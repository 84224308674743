body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

  /* Adăugăm padding dinamic pentru zona sigură în partea de jos */
.bottom-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px; /* Padding normal */
 
  z-index: 50;
}


.generate-route-btn {
  position: absolute;
  transform: translate(-50%, -100%); /* Centram butonul peste marker */
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-route-btn:hover {
  background-color: #0056b3;
}

/* src/components/map/MapComponent.css */

/* Animație pentru ieșirea div-ului de jos */
@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.destination-info {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Ocupă 80% din lățime */
  height: 20vh; /* Ocupă 20% din înălțimea ecranului */
  background-color: white;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0 0;
  z-index: 2000; /* Mai sus decât alte elemente */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  animation: slide-up 0.3s ease-out;
}

/* Stil pentru butonul de închidere */
.close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}


.search-container {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.search-results li {
  padding: 8px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

/* Marker container */
.marker-container {
  position: relative;
  width: 40px;
  height: 48px; /* Include și spațiul pentru triunghi */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

/* Cercul markerului */
.marker-circle {
  width: 30px; /* Diminuăm puțin diametrul */
  height: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adăugăm puțină umbră */
}

/* Overlay pentru a face iconița mai pală */
.icon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.25); /* Transparent 10% */
  z-index: 3; /* Se poziționează deasupra imaginii */
}

/* Iconița categoriei */
.marker-icon {
  width: 95%;
  height: 95%;
  object-fit: contain;
  z-index: 2; /* Asigurăm că iconița este deasupra overlay-ului */
}

/* Triunghiul de sub cerc */
.marker-triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(255, 255, 255, 0.9); /* Culoare albă semi-transparentă */
  position: absolute;
  bottom: -8px; /* Poziționat chiar sub cerc */
}

  /* src/index.css */
  @tailwind base;
  @tailwind components;
  @tailwind utilities;